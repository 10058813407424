@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  height: 100%;
  margin: 0;
  background: linear-gradient(247.31deg, #b9ddff 2.74%, #fee9fc 100%);
  color: #0e2782;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'Poppins', sans-serif;
}

.sidebar {
  background: linear-gradient(34.79deg, #eff5fb 19.46%, #eeefff 81.42%);
  background-repeat: no-repeat;
}

.tables {
  background: linear-gradient(
    226.05deg,
    rgba(255, 255, 255, 0.65) 5.8%,
    rgba(255, 255, 255, 0.51) 73.95%
  );
  /* backdrop-filter: blur(100px); */
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 19px;
}
.board-bg {
  background: linear-gradient(
    226.05deg,
    rgba(255, 255, 255, 0.65) 5.8%,
    rgba(255, 255, 255, 0.51) 73.95%
  );
  /* backdrop-filter: blur(100px); */
  /* Note: backdrop-filter has minimal browser support */
}

.loading {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.loading:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid rgb(96 165 250);
  border-color: rgb(96 165 250) transparent rgb(96 165 250) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1320px;
}

label {
  font-weight: 700;
}
@media (min-width: 768px) {
  .section-center {
    width: 95vw;
  }
}
